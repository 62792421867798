/* * {
  box-sizing: border-box;
}

body {
  background-color: #f1f1f1;
}

#regForm {
  background-color: #ffffff;
  margin: 100px auto;
  font-family: sans-serif;
  padding: 40px;
  width: 80%;
  min-width: 300px;
}
.ankit{
  display:flex;
    justify-content:center;
    align-items:center;
    z-index:5;
    position:absolute;
    margin-left:32%;
    flex-direction: column;
}
@media screen and (max-width: 700px) {
  #regForm {
    background-color: #ffffff;
    margin: 100px auto;
    font-family: sans-serif;
    padding: 40px;
    width: 100%;
    min-width: 300px;
  }
  .ankit{
    display:flex;
      justify-content:center;
      align-items:center;
      z-index:5;
      position:absolute;
      margin-left:28%;
      flex-direction: column;
  }
}

h1 {
  text-align: center;
}

input {
  padding: 10px;
  width: 100%;
  font-size: 17px;
  font-family: sans-serif;
  border: 1px solid #aaaaaa;
  text-transform: capitalize;
}


input.invalid {
  background-color: #ffdddd;
}

.tab {
  display: none;
}

.tablinks {
  width: 150px;
  background-color: rgb(255, 119, 0);
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

button {
  background-color: #04aa6d;
  color: #ffffff;
  border: none;
  padding: 10px 20px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

button:hover {
  opacity: 0.8;
}

#prevBtn {
  background-color: #bbbbbb;
}

.step {
  height: 15px;
  width: 15px;
  margin: 0 2px;
  background-color: #bbbbbb;
  border: none;
  border-radius: 50%;
  display: inline-block;
  opacity: 0.5;
}

.step.active {
  opacity: 1;
}

.step.finish {
  background-color: #04aa6d;
}

.form-label {
  font-size: 19px;
}

body {
  font-family: Arial;
}

.tab {
  overflow: hidden;
  border: 1px solid #ccc;
  background-color: #f1f1f1;
}

.tab button {
  background-color: inherit;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  padding: 14px 16px;
  transition: 0.3s;
  font-size: 17px;
}

.tab button:hover {
  background-color: #ddd;
}

.tab button.active {
  background-color: #ccc;
}

.tabcontent {
  display: none;
  padding: 6px 12px;
  border: 1px solid #ccc;
  border-top: none;
}

.checkbox-wrapper-17 input[type="checkbox"] {
  height: 0;
  width: 0;
  visibility: hidden;
}

.checkbox-wrapper-17 label {
  --size: 50px;

  cursor: pointer;
  width: var(--size);
  height: calc(var(--size) / 2);
  background: grey;
  display: block;
  border-radius: 100px;
  position: relative;
}

.checkbox-wrapper-17 label:after {
  content: "";
  position: absolute;
  top: 6%;
  left: 2.5%;
  width: calc(50% - 5%);
  height: calc(100% - 11%);
  background: #fff;
  border-radius: 90px;
  transition: 0.3s;
}

.checkbox-wrapper-17 input:checked+label {
  background: #bada55;
}

.checkbox-wrapper-17 input:checked+label:after {
  left: calc(100% - 2.5%);
  transform: translateX(-100%);
}

.checkbox-wrapper-17 label:active:after {
  width: 55%;
}

.modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(164, 77, 77, 0.4);
}

.modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 80%;
  max-width: 400px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.3);
}

.close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}

.close:hover,
.close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

table {
  border-collapse: collapse;
  width: 100%;
  background-color: #F2F2F2;
}

th, td {
  text-align: center;
  padding: 8px;
  border: 1px solid black;
}

tr:nth-child(even) {
  background-color: #F2F2F2;
}


.scrolling-box {
  display: block;
  width: 100%;
  height: auto;
  padding: 1em;
  overflow-y: scroll;
  text-align: center;
}
 */

/* BgcForm css */

/* Step headings */
.form-container {
  font-family: sans-serif;
  max-width: 900px;
  margin: 20px auto;
  padding: 20px;
  border: 2px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  background-color: #f9f9f9;
}

.form-header {
  display: flex;
  justify-content: space-between;
}

.step-title {
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  cursor: default;
  transition: background-color 0.3s, color 0.3s;
  font-weight: 500;
}

.step-title.active {
  background-color: #007bff;
  color: white;
  border-color: #007bff;
}

.step-title.blurred {
  opacity: 0.6;
}

.form-content {
  margin-bottom: 20px;
}

.text-center {
  text-align: center;
}

.font-bold {
  font-weight: bold;
}

.text-3xl {
  font-size: 1.875rem;
}

.text-orange-600 {
  color: #dc6803;
}

.bg-light {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  border: 2px solid #ddd;
  margin: 2px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.form-label {
  display: block;
  margin-bottom: 5px;
  font-weight: 500;
}

.inputtype {
  width: 100%;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  box-sizing: border-box;
  text-transform: capitalize;
}

.tablinks {
  width: 150px;
  background-color: rgb(255, 119, 0);
  color: #ffffff;
  border: none;
  padding: 7px 15px;
  font-size: 17px;
  font-family: Raleway;
  cursor: pointer;
}

@media (max-width: 768px) {

  .step-title.blurred {
    opacity: 0.6;
  }

  .row {
    display: flex;
    flex-wrap: wrap;
    gap: 1rem;
  }

  .col-md-4, .col-6 {
    width: calc(50% - 1rem); 
  }

  .col-md-4 {
    width: 100%; 
  }
  @media (max-width: 768px) {
    .form-header {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .step-title {
      display: none; /* Hide all step titles in mobile */
    }
  
    .step-title.active {
      display: block; /* Show only active step */
      font-size: 16px;
      font-weight: bold;
      background-color: #007bff;
      color: white;
      padding: 8px 15px;
      border-radius: 5px;
      text-align: center;
    }
  }
  
}
